exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-award-and-certification-programme-js": () => import("./../../../src/pages/award-and-certification-programme.js" /* webpackChunkName: "component---src-pages-award-and-certification-programme-js" */),
  "component---src-pages-carbon-conscious-events-js": () => import("./../../../src/pages/carbon-conscious-events.js" /* webpackChunkName: "component---src-pages-carbon-conscious-events-js" */),
  "component---src-pages-carbon-credits-js": () => import("./../../../src/pages/carbon-credits.js" /* webpackChunkName: "component---src-pages-carbon-credits-js" */),
  "component---src-pages-carbon-measured-services-js": () => import("./../../../src/pages/carbon-measured-services.js" /* webpackChunkName: "component---src-pages-carbon-measured-services-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-jargon-busters-js": () => import("./../../../src/pages/jargon-busters.js" /* webpackChunkName: "component---src-pages-jargon-busters-js" */),
  "component---src-pages-linaker-js": () => import("./../../../src/pages/linaker.js" /* webpackChunkName: "component---src-pages-linaker-js" */),
  "component---src-pages-ncz-community-js": () => import("./../../../src/pages/ncz-community.js" /* webpackChunkName: "component---src-pages-ncz-community-js" */),
  "component---src-pages-ncz-portal-js": () => import("./../../../src/pages/ncz-portal.js" /* webpackChunkName: "component---src-pages-ncz-portal-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tree-in-a-million-js": () => import("./../../../src/pages/tree-in-a-million.js" /* webpackChunkName: "component---src-pages-tree-in-a-million-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

